var scrolling = {
    about_company: true,
    open_position: true,
};


const career =  {
    animate: () => {

        var animate = function(sc_top){
            if($('#about-company').offset().top <= sc_top && scrolling.about_company){
                $('#about-company').removeClass('start');
                scrolling.about_company = false;
            }else if($('#about-company').offset().top - parseInt($(window).height() / 2) < sc_top && scrolling.about_company){
                $('#about-company').removeClass('start');
                // $("html, body").animate({scrollTop: $('#about-company').offset().top}, 1000);
                scrolling.about_company = false;
            }
            
            if($('#open-position').offset().top <= sc_top && scrolling.open_position){
                $('#open-position').removeClass('start');
                scrolling.open_position = false;
            }else if($('#open-position').offset().top - parseInt($(window).height() / 1.4) < sc_top && scrolling.open_position){
                $('#open-position').removeClass('start');
                // $("html, body").animate({scrollTop: $('#open-position').offset().top}, 1000);
                scrolling.open_position = false;
            }
        };
        
        $(document).ready(function(){
            animate($(window).scrollTop());
        });
        $(window).scroll(function(){
           animate($(window).scrollTop());
        });
    },
    
    pagenav: () => {
        var ah = {
            '#nyitott-poziciok': 'open_position'
        }
        
        var scroll = (target) =>{
            for(var i in scrolling){
                if(target != i){
                    $('#' + i.replace('_', '-')).removeClass('start');
                    scrolling[i] = false;
                }
            }

            let item = $('#' + target.replace('_', '-'));
            if(item.length > 0){
                $('html, body').animate({scrollTop: item.offset().top}, 500);
            }
        }

        if(window.location.hash != '' && ah[window.location.hash] != undefined){
            scroll(ah[window.location.hash]);
        }

        $('.page-anchor-nav').on('click', (e) => {
            let target = $(e.target).data('target');
            scroll(target);
        });
    },
}