const portfolio =  {
    pagenav: () => {
        var ah = {
            '#kutatas': 'research',
            '#strategia': 'strategy',
            '#termek-tervezes': 'product-design'
        }
        
        var scroll = (target) =>{
            for(var i in scrolling){
                if(target != i){
                    $('#' + i.replace('_', '-'));
                    scrolling[i] = false;
                }
            }

            let item = $('#' + target.replace('_', '-'));
            if(item.length > 0){
                $('html, body').animate({scrollTop: item.offset().top}, 500);
            }
        }

        if(window.location.hash != '' && ah[window.location.hash] != undefined){
            scroll(ah[window.location.hash]);
        }

        $('.page-anchor-nav').on('click', (e) => {
            let target = $(e.target).data('target');
            scroll(target);
        });
    },
}