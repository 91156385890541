const home =  {
    animate: () => {
        var scrolling = {
            projects: true,
            mission: true,
            services: true,
            partners: true,
        };

        var animate = function(sc_top){
            if($('#highligh-project').offset().top <= sc_top && scrolling.projects){
                $('#highligh-project').removeClass('start');
                scrolling.projects = false;
            }
            else if($('#highligh-project').offset().top - parseInt($(window).height() / 2) < sc_top && scrolling.projects){
                $('#highligh-project').removeClass('start');
                // $("html, body").animate({scrollTop: $('#highligh-project #carousel').offset().top}, 1000);
                scrolling.projects = false;
            }

            if($('#mission').offset().top <= sc_top && scrolling.mission){
                $('#mission').removeClass('start');
                scrolling.mission = false;
            }else if($('#mission').offset().top - parseInt($(window).height() / 1.4) < sc_top && scrolling.mission){
                $('#mission').removeClass('start');
                // $("html, body").animate({scrollTop: $('#mission').offset().top}, 1000);
                scrolling.mission = false;
            }
            
            if($('#services').offset().top <= sc_top && scrolling.services){
                $('#services').removeClass('start');
                scrolling.services = false;
            }else if($('#services').offset().top - parseInt($(window).height() / 1.4) < sc_top && scrolling.services){
                $('#services').removeClass('start');
                // $("html, body").animate({scrollTop: $('#services').offset().top}, 1000);
                scrolling.services = false;
            }

            if($('#partners').offset().top <= sc_top && scrolling.partners){
                $('#partners').removeClass('start');
                scrolling.partners = false;
            }else if($('#partners').offset().top - parseInt($(window).height() / 1.4) < sc_top && scrolling.partners){
                $('#partners').removeClass('start');
                // $("html, body").animate({scrollTop: $('#partners').offset().top}, 1000);
                scrolling.partners = false;
            }
        };
        
        $(document).ready(function(){
            animate($(window).scrollTop());
        });
        $(window).scroll(function(){
           animate($(window).scrollTop());
        });
    }
}